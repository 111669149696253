import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    startDate: false,
    endDate: false,
    adjustMap: true,
  },
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setMapAdjust: (state, action) => {
      state.adjustMap = action.payload;
    },
  },
});

export const { setStartDate, setEndDate, setMapAdjust } = searchSlice.actions;

export default searchSlice.reducer;
