import { Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../lib/axios";
import { IoIosClose } from "react-icons/io";

import "./notification.scss";

export default function Notification(props) {
  const [notification, setNotification] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [message, setMessage] = useState("");

  useEffect(() => {
    api.interceptors.response.use(
      (response) => {
        if(response.data.status && response.data.status === 'success') {
          setMessage(response.data.message);
          setNotificationType('success');
          setNotification(true);
        }
        
        return response;
      },
      (error) => {
        // whatever you want to do with
        if (error.response) {
          setMessage(error.response.data.message);
          setNotificationType('error');
          setNotification(true);
        }
        throw error;
      }
    );
  });

  const handleClose = () => {
    setMessage("");
    setNotification(false);
  };

  const action = (
    <>
      <button onClick={handleClose} className="close-button">
        <IoIosClose size={25} fill="white" />
      </button>
    </>
  );

  return (
    <>
      {props.children}
      <Snackbar
        className={`notifications ${notificationType === 'error' ? 'error' : 'success'}`}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={notification}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </>
  );
}
