import "./user.scss";

import UserLogo from "../../assets/images/user.png";
import EditLogo from "../../assets/images/edit.png";

export default function User(props) {
  const { user, onEdit } = props;

  return (
    <div className="user-card">
      <div className="logo">
        <img src={UserLogo} alt="user-logo" />
      </div>
      <div className="name">
        {user.name}
      </div>
      <div className="edit-button">
        <button className="edit" onClick={onEdit}>
            <img src={EditLogo} alt="edit" />
        </button>
      </div>
    </div>
  );
}
