import Map from '../../components/Map/Map';
import Header from '../../components/Header/Header';

export default function Home() {

  return (
    <>
      <Header switchCart/>
      <Map />
    </>
  )
}
