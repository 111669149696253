import "./cart-item.scss";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItem,
  updateItem,
  addSelectedItem,
  removeSelectedItem,
} from "../../lib/slices/cartSlice";

import Delete from "../../assets/images/delete.png";
import Placeholder from "../../assets/images/img-cart.png";
import Calendar from "../../assets/images/calendar.png";
import Check from "../../assets/images/check.png";
import { useMap } from "react-leaflet";
import { CiMap } from "react-icons/ci";
import Popover from "@mui/material/Popover";
import DatePicker from "react-datepicker";
import { useState } from "react";
import _ from "lodash";

export default function CartItem(props) {
  const { item } = props;
  const dispatch = useDispatch();
  const map = useMap();
  const [dateIsOpen, setDateIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs(item.startDate).toDate());
  const [endDate, setEndDate] = useState(dayjs(item.endDate).toDate());
  const { selectedItems } = useSelector((state) => state.cart);

  const today = new Date();

  const handleChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      const newItem = {
        ...item,
        startDate: startDate ? startDate.toJSON() : null,
        endDate: endDate ? endDate.toJSON() : null,
      };
      dispatch(updateItem(newItem));
    }
  };

  const selected = _.findIndex(selectedItems, (mupi) => mupi._id === item._id) !== -1;

  return (
    <div className={`cart-item ${selected ? "selected" : ""}`}>
      <div className="cart-item__header">
        <button
          className="icon checkbox"
          onClick={() => {
            if (!selected) {
              dispatch(addSelectedItem(item));
            } else {
              dispatch(removeSelectedItem(item));
            }
          }}
        >
          <img src={Check} alt="check" />
        </button>
        <div className="cart-item__header__buttons">
          <button
            className="icon map"
            onClick={() => {
              map.setView(
                [item.location.coordinates[0], item.location.coordinates[1]],
                18,
                {
                  animate: true,
                  duration: 1,
                }
              );
            }}
          >
            <CiMap />
          </button>
          <button
            className="icon bordered"
            onClick={() => dispatch(removeItem(item))}
          >
            <img src={Delete} alt="delete" />
          </button>
        </div>
      </div>
      <div className="cart-item__content">
        <div className="image">
          <img src={Placeholder} alt="image_cart" />
        </div>
        <div className="info">
          {item.concelho && (
            <div className="cart-item__content__item">
              <div className="title">Concelho: </div>
              <div className="value">{item.concelho}</div>
            </div>
          )}
          {item.network && (
            <div className="cart-item__content__item">
              <div className="title">Rede: </div>
              <div className="value">{item.network}</div>
            </div>
          )}
          <div className="cart-item__content__item">
            <div className="title">Serie:</div>
            <div className="value">{item.serie}</div>
          </div>
          <div className="cart-item__content__availability">
            {item.isFree ? (
              <div className="free">Livre para Utilização</div>
            ) : (
              <div className="busy">
                Ocupado até {dayjs(item.until).format("DD/MM/YYYY")}
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        id={`calendar-${item._id}`}
        className="cart-item__calendar"
        onClick={() => setDateIsOpen(true)}
      >
        <div className="icon">
          <img src={Calendar} alt="Calendar" />
        </div>
        <div className="dates">
          {dayjs(item.startDate).format("DD/MM/YY")} -{" "}
          {dayjs(item.endDate).format("DD/MM/YY")}
        </div>
      </button>
      <Popover
        className="search-popover"
        open={dateIsOpen}
        onClose={() => {
          setDateIsOpen(false);
        }}
        anchorEl={document.getElementById(`calendar-${item._id}`)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DatePicker
          minDate={today}
          dateFormat="dd/MM/yyyy"
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          isClearable
        />
      </Popover>
    </div>
  );
}
