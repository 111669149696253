import { Route, Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "./pages/Home";
import View from "./pages/View";
import Login from "./pages/Login";
import Equipment from "./pages/Equipment";
import { useAuth } from "./providers/authProvider";
import { useEffect } from "react";
import { LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";

const history = createBrowserHistory();

export default function Routes() {
  const loading = useSelector((state) => state.mupi.loading);
  const auth = useAuth();
  return (
    <>
      <div className={`loading ${!loading ? "hide" : ""}`}>
        <LinearProgress />
      </div>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <HomeRoute exact path="/" auth={auth}>
            <Home />
          </HomeRoute>
          <EquipmentRoute auth={auth}>
            <Route path="/equipment" component={Equipment} />
          </EquipmentRoute>
          <Route path="/view/:hash" component={View} />
        </Switch>
      </Router>
    </>
  );
}

function HomeRoute({ auth, children, ...rest }) {
  /* eslint-disable */
  useEffect(() => {
    auth.signin(() => {
      history.replace("/");
    });
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function EquipmentRoute({ auth, children, ...rest }) {
  /* eslint-disable */
  useEffect(() => {
    auth.signin(() => {
      history.replace("/equipment");
    });
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
