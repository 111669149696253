import { useState } from "react";
import { useMap } from "react-leaflet";
import Select from "react-select";
import _ from "lodash";

export default function OptionSelect(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const map = useMap();
  const { onChangeChoices, onRef } = props;

  return (
    <Select
      {...props}
      ref={(ref) => {
        if (onRef) {
          onRef(ref);
        }
      }}
      onMenuOpen={() => {
        map.doubleClickZoom.disable();
        map.dragging.disable();
        map.scrollWheelZoom.disable();
        map.touchZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }}
      onMenuClose={() => {
        map.doubleClickZoom.enable();
        map.dragging.enable();
        map.scrollWheelZoom.enable();
        map.touchZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }}
      defaultValue={selectedOption}
      onChange={(values) => {
        setSelectedOption(values);
        if (values) {
          if (!_.isArray(values)) {
            onChangeChoices([values.value]);
          } else {
            onChangeChoices(values.map((value) => value.value));
          }
        } else {
          onChangeChoices([]);
        }
      }}
      isMulti
      isSearchable
      isClearable={false}
      styles={{
        container: (styles) => {
          return {
            ...styles,
            width: "100%",
          };
        },
        control: (styles) => {
          return {
            ...styles,
            border: "1px solid #C2D1D9",
            borderRadius: "5px",
            padding: "10px 15px",
            ":hover": {
              borderColor: "rgba(1, 129, 186, 0.3)",
            },
          };
        },
        option: (styles) => {
          return {
            ...styles,
            backgroundColor: "white",
            ":hover": { backgroundColor: "#3BB7D2", color: "white" },
          };
        },
        input: (styles) => {
          return {
            ...styles,
            padding: 0,
            margin: 0,
          };
        },
        indicatorSeparator: () => false,
        dropdownIndicator: (styles) => {
          return {
            ...styles,
            padding: 0,
          };
        },
        clearIndicator: (styles) => {
          return {
            ...styles,
            padding: 0,
          };
        },
        valueContainer: (styles) => {
          return {
            ...styles,
            padding: 0,
          };
        },
        multiValueLabel: (styles) => {
          return {
            ...styles,
            padding: 0,
            paddingLeft: 0,
            background: "white",
          };
        },
        multiValueRemove: (styles) => {
          return {
            ...styles,
            padding: 0,
            background: "white",
            ":hover": {
              background: "white",
              "> svg > *": {
                fill: "#0181BA",
              },
            },
          };
        },
        placeholder: (styles) => {
          return {
            ...styles,
            color: "black",
            fontFamily: "Poppins",
            fontSize: "11px",
            margin: 0,
          };
        },
      }}
    />
  );
}
