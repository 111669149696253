import Map from '../../components/Map/Map';
import Header from '../../components/Header/Header';

export default function View() {
  return (
    <>
      <Header />
      <Map onlyView="true"/>
    </>
  )
}