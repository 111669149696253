import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import EquipmentMap from "../../components/Map/EquipmentMap";
import Header from "../../components/Header/Header";

export default function Equipment() {
  const loadingMonoposte = useSelector((state) => state.monoposte.loading);
  const loadingOutdoor = useSelector((state) => state.outdoor.loading);
  const loadingSinaletica = useSelector((state) => state.sinaletica.loading);
  return (
    <>
      <Header switchCart />
      <div
        className={`loading ${
          !loadingMonoposte && !loadingOutdoor && !loadingSinaletica
            ? "hide"
            : ""
        }`}
      >
        <LinearProgress />
      </div>
      <EquipmentMap />
    </>
  );
}
