import axios from "axios";

const api = axios.create({
  baseURL: `http://164.92.196.210/api`,
  headers: {
    "x-token": localStorage.getItem("x-token"),
  },
});

export default api;
