import "./profile.scss";

import UserLogo from "../../assets/images/user.png";
import Close from "../../assets/images/close.png";
import Back from "../../assets/images/back.png";
import Delete from "../../assets/images/delete.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAuth } from "../../providers/authProvider";
import api from "../../lib/axios";
import { useEffect, useState } from "react";
import User from "../User/User";

export default function Profile(props) {
  const { onClose } = props;
  const [value, setValue] = useState(0);
  const [users, setUsers] = useState([]);
  const [editModeUser, setEditModeUser] = useState(null);
  const auth = useAuth();

  /* eslint-disable */
  useEffect(() => {
    if (auth.user.role === "ADMIN") {
      const fetchData = async () => {
        const usersResult = await api({
          url: "/user/users",
          method: "GET",
        });
        setUsers(usersResult.data);
      };
      fetchData();
    }
  }, []);

  const ChangeUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(8, "Demasiado pequeno")
      .required("Campo obrigatório"),
    password: Yup.string().min(8, "Password demasiada pequena"),
    verify_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "As passwords devem coincidir"
    ),
  });
  const AddUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(8, "Demasiado pequeno")
      .required("Campo obrigatório"),
    email: Yup.string()
      .email("Não é um email válido")
      .required("Campo obrigatório"),
    password: Yup.string()
      .min(8, "Password demasiada pequena")
      .required("Campo obrigatório"),
    verify_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "As passwords devem coincidir"
    ),
  });
  const EditUserSchema = Yup.object().shape({
    name: Yup.string()
      .min(8, "Demasiado pequeno")
      .required("Campo obrigatório"),
    email: Yup.string()
      .email("Não é um email válido")
      .required("Campo obrigatório"),
    password: Yup.string().min(8, "Password demasiada pequena"),
    verify_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "As passwords devem coincidir"
    ),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  return (
    <div className="profile">
      <button onClick={onClose} className="close">
        <img src={Close} alt="close" />
      </button>
      <div className="logo">
        <img src={UserLogo} alt="User Logo" />
      </div>
      {(auth.user.role === "ADMIN" && (
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Profile Tabs"
          className="tabs"
        >
          <Tab label="Perfil" {...a11yProps(0)} className="tab-button" />
          <Tab label="Utilizadores" {...a11yProps(1)} className="tab-button" />
        </Tabs>
      )) || <div className="title">Perfil</div>}
      <TabPanel value={value} index={0} className="profile-tab">
        <Formik
          initialValues={{
            name: auth.user.name,
            email: auth.user.email,
          }}
          validationSchema={ChangeUserSchema}
          onSubmit={async (values) => {
            try {
              await api({
                method: "post",
                data: values,
                url: "/user/change-me",
              });
              auth.signin(() => {
                onClose();
              });
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="form">
              <div className="inputs">
                <div className="name">
                  <Field
                    name="name"
                    type="name"
                    placeholder="Nome utilizador"
                  />
                  <div className="errors">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="email">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Email"
                    disabled
                  />
                </div>
                {auth.user.role === "ADMIN" && (
                  <>
                    <div className="password">
                      <Field
                        name="password"
                        type="password"
                        placeholder="Alterar Password"
                      />
                      <div className="errors">
                        <ErrorMessage name="password" />
                      </div>
                    </div>
                    <div className="verify_password">
                      <Field
                        name="verify_password"
                        type="password"
                        placeholder="Confirmar Password"
                      />
                      <div className="errors">
                        <ErrorMessage name="verify_password" />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <button type="submit" className="save">
                Guardar Alterações
              </button>
            </Form>
          )}
        </Formik>
      </TabPanel>
      {auth.user.role === "ADMIN" && (
        <TabPanel value={value} index={1} className="users">
          {!editModeUser && (
            <>
              <div className="users-list">
                {users.map((user) => {
                  return (
                    <User
                      user={user}
                      key={user._id}
                      onEdit={() => setEditModeUser(user)}
                    />
                  );
                })}
              </div>
              <button
                className="add-new"
                onClick={() => setEditModeUser("add")}
              >
                Adicionar Novo Utilizador
              </button>
            </>
          )}
          {editModeUser && editModeUser !== "add" && (
            <>
              <div className="edit-header">
                <button className="icon" onClick={() => setEditModeUser(null)}>
                  <img src={Back} alt="edit" />
                </button>
                <div className="edit-title">Editar utilizador</div>
                <button
                  className="icon delete"
                  onClick={async () => {
                    await api({
                      method: "post",
                      data: {
                        id: editModeUser.id,
                      },
                      url: "/user/delete-user",
                    });
                    const usersResult = await api({
                      url: "/user/users",
                      method: "GET",
                    });
                    setUsers(usersResult.data);
                    setEditModeUser(null);
                  }}
                >
                  <img src={Delete} alt="delete" />
                </button>
              </div>
              <Formik
                initialValues={{
                  name: editModeUser.name,
                  email: editModeUser.email,
                  role: editModeUser.role,
                }}
                validationSchema={EditUserSchema}
                onSubmit={async (values) => {
                  try {
                    await api({
                      method: "post",
                      data: {
                        id: editModeUser.id,
                        ...values,
                      },
                      url: "/user/change-user",
                    });
                    const usersResult = await api({
                      url: "/user/users",
                      method: "GET",
                    });
                    setUsers(usersResult.data);
                    setEditModeUser(null);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="form">
                    <div className="inputs">
                      <div className="name">
                        <Field
                          name="name"
                          type="name"
                          placeholder="Nome utilizador"
                        />
                        <div className="errors">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                      <div className="email">
                        <Field name="email" type="email" placeholder="Email" />
                        <div className="errors">
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                      <div className="role">
                        <Field as="select" name="role">
                          <option value="USER">Comercial</option>
                          <option value="ADMIN">Administrador</option>
                        </Field>
                      </div>
                      <div className="password">
                        <Field
                          name="password"
                          type="password"
                          placeholder="Alterar Password"
                        />
                        <div className="errors">
                          <ErrorMessage name="password" />
                        </div>
                      </div>
                      <div className="verify_password">
                        <Field
                          name="verify_password"
                          type="password"
                          placeholder="Confirmar Password"
                        />
                        <div className="errors">
                          <ErrorMessage name="verify_password" />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="save">
                      Guardar Alterações
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {editModeUser === "add" && (
            <>
              <div className="edit-header">
                <button className="icon" onClick={() => setEditModeUser(null)}>
                  <img src={Back} alt="edit" />
                </button>
                <div className="edit-title">Adicionar novo utilizador</div>
                <div />
              </div>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  role: "USER",
                }}
                validationSchema={AddUserSchema}
                onSubmit={async (values) => {
                  try {
                    await api({
                      method: "post",
                      data: values,
                      url: "/user/signup",
                    });
                    const usersResult = await api({
                      url: "/user/users",
                      method: "GET",
                    });
                    setUsers(usersResult.data);
                    setEditModeUser(null);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                {({ errors, touched }) => (
                  <Form className="form">
                    <div className="inputs">
                      <div className="name">
                        <Field
                          name="name"
                          type="name"
                          placeholder="Nome utilizador"
                        />
                        <div className="errors">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                      <div className="email">
                        <Field name="email" type="email" placeholder="Email" />
                        <div className="errors">
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                      <div className="role">
                        <Field as="select" name="role">
                          <option value="USER">Comercial</option>
                          <option value="ADMIN">Administrador</option>
                        </Field>
                      </div>
                      <div className="password">
                        <Field
                          name="password"
                          type="password"
                          placeholder="Password"
                        />
                        <div className="errors">
                          <ErrorMessage name="password" />
                        </div>
                      </div>
                      <div className="verify_password">
                        <Field
                          name="verify_password"
                          type="password"
                          placeholder="Confirmar Password"
                        />
                        <div className="errors">
                          <ErrorMessage name="verify_password" />
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="save">
                      Adicionar Utilizador
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </TabPanel>
      )}
    </div>
  );
}
