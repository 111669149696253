import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: [],
    count: 0,
    selectedItems: [],
  },
  reducers: {
    addItem: (state, action) => {
      state.value.push(action.payload);
      state.count += 1;
    },
    removeItem: (state, action) => {
      state.selectedItems = _.pullAllBy(
        state.selectedItems,
        [action.payload],
        "_id"
      );
      state.value = _.pullAllBy(state.value, [action.payload], "_id");
      state.count -= 1;
    },
    updateItem: (state, action) => {
      const index = state.value.findIndex(
        (item) => item._id === action.payload._id
      );
      state.value[index] = action.payload;
    },
    addItems: (state, action) => {
      const diff = _.differenceBy(action.payload, state.value, "_id");
      state.value = _.concat(state.value, diff);
      state.count = state.value.length;
    },
    removeItems: (state, action) => {
      state.selectedItems = _.pullAllBy(
        state.selectedItems,
        action.payload,
        "_id"
      );
      state.value = _.pullAllBy(state.value, action.payload, "_id");
      state.count = state.value.length;
    },
    addSelectedItem: (state, action) => {
      const index = state.selectedItems.findIndex(
        (item) => item._id === action.payload._id
      );
      if (index === -1) {
        state.selectedItems.push(action.payload);
      }
    },
    removeSelectedItem: (state, action) => {
      state.selectedItems = _.pullAllBy(
        state.selectedItems,
        [action.payload],
        "_id"
      );
    },
    clear: (state) => {
      state.value = [];
      state.selectedItems = [];
      state.count = 0;
    },
  },
});

export const {
  addItem,
  removeItem,
  addItems,
  removeItems,
  updateItem,
  addSelectedItem,
  removeSelectedItem,
  clear,
} = cartSlice.actions;

export default cartSlice.reducer;
