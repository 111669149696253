import { createContext, useState, useContext } from "react";
import api from "../lib/axios";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

function useProvideAuth() {
  const [user, setAuthUser] = useState(null);

  const signin = async (cb) => {
    try {
      const responseUser = await api({
        url: "/user/me",
        method: "get",
      });
      setAuthUser(responseUser.data);
      cb();
    } catch (err) {
      console.log(err.message);
    }
  };

  const signout = (cb) => {
    localStorage.removeItem("x-token");
    api.defaults.headers["x-token"] = null;
    setAuthUser(null);
    cb();
  };

  return {
    user,
    signin,
    signout,
  };
}

export function AuthProvider(props) {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}
