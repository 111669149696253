import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slices/cartSlice";
import sidebarReducer from "./slices/sidebarSlice";
import monoposteReducer from "./slices/monoposteSlice";
import mupiReducer from "./slices/mupiSlice";
import outdoorReducer from "./slices/outdoorSlice";
import sinaleticaReducer from "./slices/sinaleticaSlice";
import searchReducer from "./slices/searchSlice";

export default configureStore({
  reducer: {
    cart: cartReducer,
    sidebar: sidebarReducer,
    monoposte: monoposteReducer,
    mupi: mupiReducer,
    outdoor: outdoorReducer,
    sinaletica: sinaleticaReducer,
    search: searchReducer,
  },
});
