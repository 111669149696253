import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";
import { useParams } from "react-router-dom";

import "./map.scss";
import "leaflet/dist/leaflet.css";
import MarkerPopup from "../Marker-popup/MarkerPopup";
import Search from "../Search/Search";
import { useAuth } from "../../providers/authProvider";
import { useSelector, useDispatch } from "react-redux";
import { getMupis } from "../../lib/slices/mupiSlice";
import _ from "lodash";
import Sidebar from "../Sidebar/Sidebar";

const createClusterCustomIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "custom-marker-cluster",
    iconSize: L.point(40, 40, true),
  });
};

const AdjustMap = () => {
  const map = useMap();
  const data = useSelector((state) => state.mupi.value);
  const coords = data.map((data) => {
    return data.location.coordinates;
  });
  if (coords.length > 1) {
    const line = lineString(coords);
    const all_bounds = bbox(line);
    if (map) {
      map.fitBounds(
        L.latLngBounds(
          L.latLng(all_bounds[0], all_bounds[1]),
          L.latLng(all_bounds[2], all_bounds[3])
        ),
        { padding: [50, 50] }
      );
    }
  } else {
    if (map) {
      if (coords.length > 0) {
        map.setView([coords[0][0], coords[0][1]], 16);
      } else {
        map.panTo([39.557191, -7.8536599]);
        map.setZoom(7);
      }
    }
  }
  return null;
};

export default function Map(props) {
  const mupis = useSelector((state) => state.mupi);
  const { adjustMap } = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const auth = useAuth();
  const { hash } = useParams();

  if (mupis.error) {
    return <p>{mupis.error}</p>;
  }

  return (
    <MapContainer
      zoomControl={false}
      center={[39.557191, -7.8536599]}
      zoom={7}
      scrollWheelZoom={true}
      whenReady={(e) => {
        dispatch(getMupis({ hash }));
      }}
    >
      {adjustMap && <AdjustMap />}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!_.isEmpty(mupis.initialValue) && auth.user && <Search />}
      {!_.isEmpty(mupis.value) && (
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createClusterCustomIcon}
        >
          {mupis.value.map((muppi) => {
            return (
              muppi.location.coordinates[0] &&
              muppi.location.coordinates[1] && (
                <MarkerPopup
                  muppi={muppi}
                  onlyView={props.onlyView}
                  key={muppi._id}
                />
              )
            );
          })}
        </MarkerClusterGroup>
      )}
      {auth.user && <Sidebar />}
    </MapContainer>
  );
}
