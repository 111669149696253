import Routes from "./Routes";
import "./App.scss";
import "react-datepicker/src/stylesheets/datepicker.scss";
import "./components/Search/calendar.scss";

import { AuthProvider } from "./providers/authProvider";
import Notification from "./components/Notification/Notification";
import { Provider } from "react-redux";
import store from "./lib/store";

function App() {
  return (
    <Provider store={store}>
      <Notification>
        <AuthProvider>
          <div className="App">
            <Routes />
          </div>
        </AuthProvider>
      </Notification>
    </Provider>
  );
}

export default App;
