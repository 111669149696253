import { Disclosure } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import Popover from "@mui/material/Popover";

import Logo from "./images/logo.png";
import Divider from "../../assets/images/divider.png";
import UserLogo from "../../assets/images/user.png";

import "./header.scss";
import { useAuth } from "../../providers/authProvider";
import { useState } from "react";
import Profile from "../Profile/Profile";

export default function Header() {
  let auth = useAuth();
  let history = useHistory();
  const [profile, toggleProfile] = useState(false);

  const signout = () => {
    auth.signout(() => {
      history.go("/login");
    });
  };

  const handleUserProfile = () => {
    toggleProfile(!profile);
  };

  return (
    <Disclosure as="nav" className="bg-gray-50">
      <>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="header">
                <div className="logo">
                  <img className="h-8 w-auto" src={Logo} alt="Girodmedias" />
                </div>
                {auth.user && (
                  <div className="perfil">
                    <button className="user" onClick={handleUserProfile}>
                      <div className="user-logo">
                        <img src={UserLogo} alt="user-logo" />
                      </div>
                      <div className="name">{auth.user.name}</div>
                    </button>
                    <div>
                      <img src={Divider} alt="divider" />
                    </div>
                    <button className="button" onClick={signout}>
                      Sair
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      {auth.user && (
        <Popover
          className="profile-popup"
          open={profile}
          anchorEl={document.body}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Profile onClose={() => toggleProfile(false)} />
        </Popover>
      )}
    </Disclosure>
  );
}
