import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";
import api from "../../lib/axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "./login.scss";

import Logo from "../../assets/images/girodmedias_logo.png";
import User from "../../assets/images/user.png";

export default function Login() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  let login = async (login) => {
    try {
      const responseToken = await api({
        url: "/user/sign-in",
        method: "post",
        data: {
          email: login.email,
          password: login.password,
        },
      });
      localStorage.setItem("x-token", responseToken.data.token);
      api.defaults.headers["x-token"] = responseToken.data.token;
      setTimeout(
        () =>
          auth.signin(() => {
            history.replace(from);
          }),
        500
      );
    } catch (err) {
      console.log(err);
    }
  };

  const SigninSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Demasiado pequeno")
      .required("Campo obrigatório"),
    email: Yup.string().email("Email Invalido").required("Campo obrigatório"),
  });

  return (
    <div className="login-form">
      <div className="logo">
        <img src={Logo} alt="Girodmedias" />
      </div>
      <div className="form-card">
        <div className="user-logo">
          <img src={User} alt="User Logo" />
        </div>
        <div className="title">Iniciar Sessão</div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={SigninSchema}
          onSubmit={(values) => {
            try {
              login(values);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="form">
              <div className="email">
                <Field name="email" type="email" placeholder="E-mail" />
                <div className="errors">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className="password">
                <Field name="password" type="password" placeholder="Password" />
                <div className="errors">
                  <ErrorMessage name="password" />
                </div>
              </div>
              <button type="submit">Entrar</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
