import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { FiMap } from "react-icons/fi";

import { Icon } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
} from "../../lib/slices/cartSlice";
import { open } from "../../lib/slices/sidebarSlice";

import MuppiImage from "../../assets/images/muppi_image.png";
import BlueMarker from "../Map/images/blue_marker.png";
import RedMarker from "../Map//images/red_marker.png";
import GreenMarker from "../Map//images/green_marker.png";
import _ from "lodash";

import "./markerPopup.scss";

dayjs.extend(weekday);

function MarkerPopup(props) {
  const { muppi, onlyView } = props;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.value);
  const mupis = useSelector((state) => state.mupi.value);
  const { startDate, endDate } = useSelector((state) => state.search);

  const markerBlueMarker = new Icon({
    iconUrl: BlueMarker,
    iconSize: [40, 45],
  });

  const markerRedMarker = new Icon({
    iconUrl: RedMarker,
    iconSize: [40, 45],
  });

  const makerGreenMarker = new Icon({
    iconUrl: GreenMarker,
    iconSize: [40, 45],
  });

  const handleAddCart = (muppi, all = false) => {
    if (!all) {
      const newMuppi = {
        ...muppi,
        startDate: startDate ? dayjs(startDate).toJSON() : dayjs().weekday(3).toJSON(),
        endDate: endDate ? dayjs(endDate).toJSON() : dayjs().weekday(3).add(1, "week").toJSON(),
      };
      dispatch(addItem(newMuppi));
    } else {
      let muppiesNetwork = _.filter(
        mupis,
        (mupi) => mupi.network === muppi.network
      );
      muppiesNetwork = _.map(muppiesNetwork, (mupi) => {
        return {
          ...mupi,
          startDate: startDate ? dayjs(startDate).toJSON() : dayjs().weekday(3).toJSON(),
          endDate: endDate ? dayjs(endDate).toJSON() : dayjs().weekday(3).add(1, "week").toJSON(),
        };
      });
      dispatch(addItems(muppiesNetwork));
    }
    dispatch(open());
  };
  const handleRemoveCart = (muppi, all = false) => {
    if (!all) {
      dispatch(removeItem(muppi));
    } else {
      dispatch(
        removeItems(_.filter(mupis, (mupi) => mupi.network === muppi.network))
      );
    }
    dispatch(open());
  };

  const allNetworkInCart = () => {
    if (muppi.network !== "") {
      const mupisFromNetwork = _.filter(
        mupis,
        (mupi) => mupi.network === muppi.network
      );
      const cartFromNetwork = _.filter(
        cart,
        (mupi) => mupi.network === muppi.network
      );
      const diff = _.differenceBy(mupisFromNetwork, cartFromNetwork, "_id");
      if (_.isEmpty(diff)) {
        return true;
      }
    }
    return false;
  };

  return (
    <Marker
      position={[muppi.location.coordinates[0], muppi.location.coordinates[1]]}
      icon={
        cart.some((pin) => pin.serie === muppi.serie)
          ? markerBlueMarker
          : muppi.isFree
          ? makerGreenMarker
          : markerRedMarker
      }
    >
      <Popup>
        <div className="marker-popup">
          <div className="marker-popup__header">
            <div className="image">
              <img src={MuppiImage} alt="Muppi Imagem" />
            </div>
            <a
              href={`http://maps.google.com/maps?q=&layer=c&cbll=${muppi.location.coordinates[0]},${muppi.location.coordinates[1]}`}
              target="_blank"
              className="street_view"
              rel="noreferrer"
            >
              <div className="street_view_link">
                Street view <FiMap stroke="#0181BA" />
              </div>
            </a>
          </div>
          <div className="marker-popup__content">
            {muppi.concelho && (
              <div className="marker-popup__content__item">
                <div className="title">Concelho: </div>
                <div className="value">{muppi.concelho}</div>
              </div>
            )}
            {muppi.network && (
              <div className="marker-popup__content__item">
                <div className="title">Rede: </div>
                <div className="value">{muppi.network}</div>
              </div>
            )}
            <div className="marker-popup__content__item">
              <div className="title">Serie:</div>
              <div className="value">{muppi.serie}</div>
            </div>
            <div className="marker-popup__content__availability">
              {muppi.isFree ? (
                <div className="free">Livre para Utilização</div>
              ) : (
                <div className="busy">
                  Ocupado até {dayjs(muppi.until).format("DD/MM/YYYY")}
                </div>
              )}
            </div>
            {!onlyView && (
              <div className="marker-popup__content__buttons">
                {cart.some((pin) => pin.serie === muppi.serie) ? (
                  <button
                    onClick={() => {
                      handleRemoveCart(muppi);
                    }}
                    className="border_button"
                  >
                    Remover Equipamento
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleAddCart(muppi);
                    }}
                    className={
                      muppi.network ? "border_button" : "normal_button"
                    }
                  >
                    Adicionar Equipamento
                  </button>
                )}
                {muppi.network !== "" && (
                  <>
                    {!allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleAddCart(muppi, true);
                        }}
                        className="normal_button"
                      >
                        Adicionar toda rede
                      </button>
                    )}
                    {allNetworkInCart() && (
                      <button
                        onClick={() => {
                          handleRemoveCart(muppi, true);
                        }}
                        className="border_button"
                      >
                        Remover toda rede
                      </button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

export default MarkerPopup;
