import { useState } from "react";
import { useMap } from "react-leaflet";

import CaretLeft from "../../assets/images/caret-left.png";
import MonoposteMarker from "../Map/images/monoposte_blue.png";
import OutdoorMarker from "../Map/images/outdoor_blue.png";
import SinaleticaMarker from "../Map/images/sinaletica_blue.png";
import PinAtivo from "../../assets/images/pin-ativo.png";
import PinDisponivel from "../../assets/images/pin-disponivel.png";
import PinFantasma from "../../assets/images/pin-fantasma.png";
import PinOcupado from "../../assets/images/pin-ocupado.png";
import PinOferta from "../../assets/images/pin-oferta.png";
import PinRescindiu from "../../assets/images/pin-rescindiu.png";
import "./caption.scss";

function Caption() {
  const [caption, setCaption] = useState(false);
  const map = useMap();

  return (
    <div
      onMouseEnter={() => {
        map.doubleClickZoom.disable();
        map.dragging.disable();
        map.scrollWheelZoom.disable();
        map.touchZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }}
      onMouseLeave={() => {
        map.doubleClickZoom.enable();
        map.dragging.enable();
        map.scrollWheelZoom.enable();
        map.touchZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }}
    >
      <button
        className={"toggleCaption " + (caption ? "open" : "")}
        onClick={() => {
          setCaption(!caption);
        }}
      >
        <div className="icon">
          <img src={CaretLeft} alt="Left" />
        </div>
        {!caption ? `Mostrar legenda` : `Esconder legenda`}
      </button>
      <div className={"caption_container " + (caption ? "open" : "")}>
        <div className="marker-wrapper">
          <div className="marker-container">
            <img src={OutdoorMarker} alt="Outdoor" />
            <span className="caption-text">Outdoor</span>
          </div>
          <div className="marker-container">
            <img src={MonoposteMarker} alt="Monoposte" />
            <span className="caption-text">Monoposte</span>
          </div>
          <div className="marker-container">
            <img src={SinaleticaMarker} alt="Sinalética" />
            <span className="caption-text">Sinalética</span>
          </div>
        </div>
        <div className="divider"></div>
        <div className="color-wrapper">
          <div className="color-container">
            <img src={PinAtivo} alt="Pin Ativo" />
            <span className="caption-text">Ativo</span>
          </div>
          <div className="color-container">
            <img src={PinDisponivel} alt="Pin Disponível" />
            <spam className="caption-text">Disponível</spam>
          </div>
          <div className="color-container">
            <img src={PinOcupado} alt="Pin Ocupado" />
            <spam className="caption-text">Ocupado</spam>
          </div>
          <div className="color-container">
            <img src={PinRescindiu} alt="Pin Rescindiu" />
            <spam className="caption-text">Rescindiu</spam>
          </div>
          <div className="color-container">
            <img src={PinOferta} alt="Pin Oferta" />
            <spam className="caption-text">Oferta</spam>
          </div>
          <div className="color-container">
            <img src={PinFantasma} alt="Pin Fantasma" />
            <spam className="caption-text">Fantasma</spam>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Caption;
